<cg-drawer [showCloseIcon]="false" [headline]="headline" [content]="content" [footer]="footer"></cg-drawer>

<ng-template #headline>
  <cg-olb-headline [title]="'exitOverlay.title' | transloco"></cg-olb-headline>
</ng-template>

<ng-template #content>
  <div class="flex flex-col gap-2">
    <cg-paragraph [content]="{ text: 'exitOverlay.subheading' | transloco }"></cg-paragraph>
    <cg-olb-headline
      class="appointment-date-heading"
      [type]="'h3'"
      [title]="'exitOverlay.call.title' | transloco"
    ></cg-olb-headline>
    <cg-ccc-phone-info class="[&>div]:mb-0" [phoneNumber]="PhoneNumber.EXIT"></cg-ccc-phone-info>
  </div>
</ng-template>

<ng-template #footer>
  <div class="flex flex-col-reverse justify-between gap-2 t:flex-row t:justify-start t:gap-4">
    <!-- tablet/desktop version -->
    <button
      cg-base-button
      class="w-fit part-[icon-text-wrapper]:flex-row part-[text]:text-cg-anthracite part-[icon-text-wrapper]:x-[w-full,px-5,bg-cg-gray-light-70,flex-row-reverse] part-[icon]:x-[h-4,w-4] part-[icon-text-wrapper]:hover:bg-cg-anthracite-light-80 part-[icon-text-wrapper]:active:bg-cg-anthracite-light-80 m:hidden t:block [&>div>cg-icon>mat-icon>svg>g>g>g>g>g]:stroke-cg-anthracite"
      [btnText]="'exitOverlay.button.abort' | transloco"
      [icon]="backwardIcon"
      (click)="leaveComponent(true)"
      [iconPosition]="'left'"
      data-cy="exit-overlay-back-btn"
    ></button>
    <!-- mobile version -->
    <button
      cg-base-button
      class="part-[icon-text-wrapper]:x-[w-full,px-5,bg-transparent] part-[text]:x-[text-cg-anthracite-light-30,underline] part-[icon-text-wrapper]:hover:bg-transparent part-[text]:hover:text-cg-anthracite part-[text]:active:text-cg-anthracite t:hidden"
      [btnText]="'exitOverlay.button.abort' | transloco"
      (click)="leaveComponent(true)"
    ></button>
    <button
      cg-base-button
      class="w-full part-[icon-text-wrapper]:justify-center part-[icon-text-wrapper]:px-5 m:part-[icon]:hidden t:w-fit t:part-[icon]:block t:part-[icon-text-wrapper]:justify-between"
      [btnText]="'exitOverlay.button.return' | transloco"
      [icon]="forwardIcon"
      (click)="leaveComponent(false)"
    ></button>
  </div>
</ng-template>
