import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ProductService } from "@cg/olb/services";
import { CustomerCaseFacade, ProcessActions, ProductActions } from "@cg/olb/state";
import { errorToString } from "@cg/core/utils";
import {
  GetBuildDatesRequest,
  GetManufacturesResponse,
  GetProductsRequest,
  GetTypesRequest,
  Product,
  ReplacedScreensStatistic,
  SetChosenProductRequest
} from "@cg/olb/shared";
import { ChosenProduct } from "@cg/shared";

@Injectable()
export class ProductEffects {
  public getAllManufacturers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchAllManufacturers),
      mergeMap(() =>
        this.productService.getAllManufacturers().pipe(
          map((manufacturers: GetManufacturesResponse) =>
            ProductActions.fetchAllManufacturersSuccess({ payload: manufacturers })
          ),
          catchError((error: HttpErrorResponse) =>
            of(ProductActions.fetchAllManufacturersFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public getAllModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchAllModels),
      map(({ payload }: { payload: { brand: string } }) => payload),
      mergeMap((payload: { brand: string }) =>
        this.productService.getAllModelsOfManufacturer(payload).pipe(
          map((models: string[]) => ProductActions.fetchAllModelsSuccess({ payload: models })),
          catchError((error: HttpErrorResponse) =>
            of(ProductActions.fetchAllModelsFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public getAllTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchAllTypes),
      map(({ payload }: { payload: GetTypesRequest }) => payload),
      mergeMap((payload: GetTypesRequest) =>
        this.productService.getAllTypesOfModel(payload).pipe(
          map((types: string[]) => ProductActions.fetchAllTypesSuccess({ payload: types })),
          catchError((error: HttpErrorResponse) =>
            of(ProductActions.fetchAllTypesFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public getAllBuildDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchAllBuildDates),
      map(({ payload }: { payload: GetBuildDatesRequest }) => payload),
      mergeMap((payload: GetBuildDatesRequest) =>
        this.productService.getAllBuildDatesOf(payload).pipe(
          map((buildDates: string[]) => ProductActions.fetchAllBuildDatesSuccess({ payload: buildDates })),
          catchError((error: HttpErrorResponse) =>
            of(ProductActions.fetchAllBuildDatesFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public getAllProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchAllProducts),
      map(({ payload }: { payload: GetProductsRequest }) => payload),
      mergeMap((payload: GetProductsRequest) =>
        this.productService.getAllProductsOf(payload).pipe(
          map((products: Product[]) => ProductActions.fetchAllProductsSuccess({ payload: products })),
          catchError((error: HttpErrorResponse) =>
            of(ProductActions.fetchAllProductsFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public getAllReplacedScreensStatistic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchAllReplacedScreensStatistic),
      map(({ payload }: { payload: string }) => payload),
      mergeMap((payload: string) =>
        this.productService.getReplacedWindscreensStatistic(payload).pipe(
          map((replacedScreensStatistic: ReplacedScreensStatistic) =>
            ProductActions.fetchAllReplacedScreensStatisticSuccess({ payload: replacedScreensStatistic })
          ),
          catchError((error: HttpErrorResponse) =>
            of(ProductActions.fetchAllReplacedScreensStatisticFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public setChosenProductOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchAllProductsSuccess),
      map(({ payload }: { payload: Product[] }) => payload),
      filter((products: Product[]) => products.length === 1),
      withLatestFrom(this.customerCaseFacade.customerCaseId$),
      map(([products, customerCaseId]: [Product[], string]) =>
        ProductActions.setChosenProduct({
          payload: {
            id: customerCaseId,
            product: [
              {
                itemNumber: products[0].carglassNr,
                quantity: 1
              }
            ]
          }
        })
      )
    )
  );

  public setChannelSwitchReasonOnUnknownProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchAllProductsSuccess),
      map(({ payload }: { payload: Product[] }) => payload),
      filter((products: Product[]) => products.length !== 1),
      map(() =>
        ProcessActions.setChannelSwitch({
          payload: "PRODUCT_NOT_FOUND"
        })
      )
    )
  );

  public setChosenProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.setChosenProduct),
      map(({ payload }: { payload: SetChosenProductRequest }) => payload),
      mergeMap((payload: SetChosenProductRequest) =>
        this.productService.setChosenProduct(payload).pipe(
          map((products: ChosenProduct[]) => {
            this.customerCaseFacade.setShoppingCartEntries(products);
            return ProductActions.setChosenProductSuccess({ payload: products });
          }),
          catchError((error: HttpErrorResponse) =>
            of(ProductActions.setChosenProductFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private productService: ProductService,
    private customerCaseFacade: CustomerCaseFacade
  ) {}
}
