import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { ParagraphComponent } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { BaseButtonComponent, CccPhoneInfoComponent, DrawerComponent } from "@cg/shared";
import { ExitOverlayFacade } from "../../+state/exit-overlay.facade";

@Component({
  selector: "cg-exit-overlay-variant",
  templateUrl: "./exit-overlay-variant.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    DrawerComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    CccPhoneInfoComponent,
    BaseButtonComponent
  ]
})
export class ExitOverlayVariantComponent {
  private QUALTRICS_LINK = "https://belron.eu.qualtrics.com/jfe/form/SV_e99pFftycEHpdSS";

  public constructor(private exitOverlayFacade: ExitOverlayFacade) {}

  public leaveComponent(leave: boolean) {
    if (leave) {
      this.exitOverlayFacade.confirm();
    }
    this.exitOverlayFacade.close();
  }

  public openSurvey(): void {
    window.open(`${this.QUALTRICS_LINK}`, "_blank");
  }
}
