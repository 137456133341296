import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { DamageActions } from "@cg/olb/state";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { LicensePlateType, Lpn } from "@cg/shared";

@Injectable()
export class DamageTrackingEffects {
  public setLpn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DamageActions.setLpn),
        tap(({ payload }: { payload: Lpn }) => {
          const eventLabel =
            payload.type === LicensePlateType.SPECIAL ? "special-license-plate" : "standard-license-plate";
          const event: Partial<TrackingEvent> = {
            eventCategory: "olb",
            eventAction: "license-plate-entry",
            eventLabel
          };

          this.trackingService.trackEvent(event);
        })
      ),
    { dispatch: false }
  );

  public constructor(
    private actions$: Actions,
    private trackingService: TrackingService
  ) {}
}
