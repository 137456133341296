import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { InsuranceService } from "@cg/olb/services";
import { ContactDataActions, InsuranceActions } from "@cg/olb/state";
import * as Sentry from "@sentry/angular-ivy";
import { SeverityLevel } from "@sentry/types";
import { errorToString } from "@cg/core/utils";
import { Insurance, InsuranceRequest, InsuranceResponse } from "@cg/olb/shared";

@Injectable()
export class InsuranceEffects {
  public loadInsurances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsuranceActions.loadInsurances),
      mergeMap(() =>
        this.insuranceService.getInsuranceNames().pipe(
          map(({ insurances, topInsurances }: { insurances: Insurance[]; topInsurances: Insurance[] }) =>
            InsuranceActions.loadInsurancesSuccess({
              payload: {
                insurances,
                topInsurances
              }
            })
          ),
          catchError((error: HttpErrorResponse) => {
            Sentry.captureMessage(error.message, "error" as SeverityLevel);
            return of(InsuranceActions.loadInsurancesFailure({ error: errorToString(error) }));
          })
        )
      )
    )
  );

  public getInsurance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsuranceActions.getInsurance),
      mergeMap(({ payload }: { payload: InsuranceRequest }) =>
        this.insuranceService.getInsurance(payload).pipe(
          map((res: InsuranceResponse) => InsuranceActions.getInsuranceSuccess({ payload: res })),
          catchError(() => of(InsuranceActions.getInsuranceFailure()))
        )
      )
    )
  );

  public getInsuranceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsuranceActions.getInsuranceSuccess),
      filter(
        ({ payload }: { payload: InsuranceResponse }) => !!payload.city || !!payload.street || !!payload.postalCode
      ),
      mergeMap(({ payload }: { payload: InsuranceResponse }) => [
        ContactDataActions.setDriverCity({
          payload: payload.city
        }),
        ContactDataActions.setDriverStreet({
          payload: payload.street
        }),
        ContactDataActions.setDriverZip({
          payload: payload.postalCode
        })
      ])
    )
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly insuranceService: InsuranceService
  ) {}
}
