import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { combineLatest, Observable, of } from "rxjs";
import { catchError, filter, first, map, mergeMap, take, tap, withLatestFrom } from "rxjs/operators";
import { AppointmentActions, AppointmentFacade, CustomerCaseFacade, DamageFacade, ProductFacade } from "@cg/olb/state";
import { ResumeFacade } from "@cg/resume-core";
import { addWeeks, endOfDay } from "date-fns";
import { AppointmentService } from "@cg/appointment-ui";
import { PLACEHOLDER } from "@cg/core/ui";
import { errorToString } from "@cg/core/utils";
import {
  Appointment,
  AppointmentPayload,
  AppointmentResponse,
  AppointmentServiceCenterPayload,
  AppointmentValidDesiredDate,
  AptModel,
  AvailableServiceCenters,
  ChosenProduct,
  CustomerCase,
  EarliestPossibleAppointmentDateResponse,
  JobStatus,
  JobType,
  RequiredService,
  Resume,
  SetAppointmentPayload
} from "@cg/shared";

@Injectable()
export class AppointmentEffects {
  private readonly MAX_APPOINTMENT_SEARCH_WEEKS = 6;

  public getAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.getAppointments),
      withLatestFrom(this.appointmentFacade.availableServiceCenters$, this.resumeFacade.resumeResponse$),
      mergeMap(
        ([{ payload }, availableScs, resumeResponse]: [
          { payload: AppointmentPayload },
          AvailableServiceCenters[],
          Resume
        ]) => {
          if (resumeResponse?.state?.appointment) {
            payload = {
              ...payload,
              timerangeBegin: resumeResponse.state.appointment.customerAppointmentStart,
              status: resumeResponse.state.appointment.status
            };
          }

          return this.appointmentService.getAppointments(payload).pipe(
            map((value: AppointmentResponse) => {
              if (availableScs && (value.availableServiceCenters?.length === 1 || !value.availableServiceCenters)) {
                value.availableServiceCenters = availableScs;
              }

              return AppointmentActions.getAppointmentsSuccess({ payload: value });
            }),
            catchError((error: Error) => of(AppointmentActions.getAppointmentsFailure({ error: errorToString(error) })))
          );
        }
      )
    )
  );

  public fetchNextAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.fetchNextAppointments),
      withLatestFrom(this.damageFacade.requiredService$, this.appointmentFacade.selectedServiceCenterIds$),
      mergeMap(
        ([{ payload }, requiredService, serviceCenterIds]: [
          { payload: AppointmentPayload },
          RequiredService,
          string[]
        ]) => {
          if (this.isValidScId(serviceCenterIds?.[0] ?? null)) {
            payload = { ...payload, serviceCenters: serviceCenterIds };
          }

          return this.appointmentService.getAppointments(payload).pipe(
            map((value: AppointmentResponse) => {
              if (value.availableAppointments.length === 0 && !value.availableServiceCenters) {
                value = { ...value, availableServiceCenters: [] };
              }

              const mobileServiceAvailable =
                requiredService === RequiredService.REPLACE && value.availableAppointments.length === 0;

              this.appointmentFacade.setMobileServiceAvailable(mobileServiceAvailable);

              return AppointmentActions.fetchNextAppointmentsSuccess({ payload: value });
            }),
            catchError((error: Error) =>
              of(AppointmentActions.fetchNextAppointmentsFailure({ error: errorToString(error) }))
            )
          );
        }
      )
    )
  );

  public fetchNextAppointmentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.fetchNextAppointmentsSuccess),
      withLatestFrom(this.damageFacade.requiredService$),
      mergeMap(
        ([
          {
            payload: { availableAppointments }
          },
          requiredService
        ]: [{ payload: AppointmentResponse; type: string }, RequiredService]) => {
          if (availableAppointments.length === 0) {
            return [AppointmentActions.setNextLoadingLimitReached({ payload: true })];
          }

          const todayEndDate = endOfDay(new Date(Date.now()));
          const limitDate = addWeeks(todayEndDate, this.MAX_APPOINTMENT_SEARCH_WEEKS);

          const lastAppointment = [...availableAppointments]
            .sort(
              ({ availabilityPeriodEnd: a }: Appointment, { availabilityPeriodEnd: b }: Appointment) =>
                new Date(a).getTime() - new Date(b).getTime()
            )
            .pop();

          const lastAppointmentDate = new Date(lastAppointment.availabilityPeriodEnd);
          const limitReached = availableAppointments.length === 0 || lastAppointmentDate > limitDate;

          const action = AppointmentActions.setNextLoadingLimitReached({ payload: limitReached });

          if (limitReached && requiredService === RequiredService.REPLACE) {
            return [action, AppointmentActions.setMobileServiceAvailable({ payload: true })];
          }

          return [action];
        }
      )
    )
  );

  public getServiceCenters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.getServiceCenters),
      mergeMap((action: { payload: AppointmentServiceCenterPayload }) =>
        combineLatest([
          of(action),
          this.customerCaseFacade.customerCaseId$,
          this.productFacade.selectedProduct$.pipe(
            filter((selectedProduct: ChosenProduct) => !!selectedProduct),
            first()
          )
        ])
      ),
      mergeMap(
        ([{ payload }, customerCaseId, selectedProduct]: [
          { payload: AppointmentServiceCenterPayload },
          string,
          ChosenProduct
        ]) => {
          const serviceCentersPayload: AppointmentServiceCenterPayload = { ...payload, customerCaseId };
          serviceCentersPayload.itemNumbers = [selectedProduct.itemNumber];

          return this.appointmentService.getServiceCenters(serviceCentersPayload).pipe(
            map((value: AppointmentResponse) => AppointmentActions.getServiceCentersSuccess({ payload: value })),
            catchError((error: Error) =>
              of(AppointmentActions.getServiceCentersFailure({ error: errorToString(error) }))
            )
          );
        }
      )
    )
  );

  public confirmAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.confirmAppointment),
      mergeMap(({ payload }: { payload: SetAppointmentPayload }) =>
        this.appointmentService.confirmAppointment(payload).pipe(
          map(() => AppointmentActions.confirmAppointmentSuccess({ payload })),
          catchError((error: Error) =>
            of(AppointmentActions.confirmAppointmentFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public confirmAppointmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppointmentActions.confirmAppointmentSuccess),
        tap(({ payload }: { payload: SetAppointmentPayload }) =>
          this.appointmentFacade.setCurrentAppointment(payload as Appointment)
        )
      ),
    { dispatch: false }
  );

  public validateAppointmentDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.validateAppointmentDate),
      mergeMap(({ payload }: { payload: AppointmentPayload }) =>
        this.appointmentService.validateAppointmentDate(payload).pipe(
          map((value: AppointmentValidDesiredDate) =>
            AppointmentActions.validateAppointmentDateSuccess({ payload: value })
          ),
          catchError((error: Error) =>
            of(AppointmentActions.validateAppointmentDateFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public fetchAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AppointmentActions.clearAndRefetchAppointments,
        AppointmentActions.saveAutoCompleteResult,
        AppointmentActions.fetchTeslaAppointments
      ),
      withLatestFrom(
        this.appointmentFacade.status$,
        this.appointmentFacade.serviceCenterLatLng$,
        this.appointmentFacade.selectedServiceCenterIds$,
        this.damageFacade.requiredService$,
        this.customerCaseFacade.customerCaseId$,
        this.appointmentFacade.formattedAddress$
      ),
      this.waitForProductId(),
      map(
        ([action, status, position, serviceCenterIds, jobType, customerCaseId, formattedAddress, itemNumbers]: [
          (
            | {
                payload: { timerangeBegin?: string; serviceCenterId: string };
                type: "[Appointment] Clear And Refetch Appointment";
              }
            | {
                payload: { timerangeBegin?: string; serviceCenterId: string };
                type: "[Appointment] Clear And Refetch Tesla Appointment";
              }
            | {
                payload: { lat?: string; lng?: string; timerangeBegin?: string };
                type: "[Appointment] Save Autocomplete Result";
              }
          ),
          JobStatus,
          { lat: string; lng: string },
          string[],
          RequiredService,
          string,
          string,
          string[]
        ]) => {
          if (action.type === AppointmentActions.saveAutoCompleteResult.type) {
            position = { lat: action.payload.lat, lng: action.payload.lng };
          }
          const isFetchTeslaAppointments = action.type === AppointmentActions.fetchTeslaAppointments.type;
          const payload: AppointmentPayload = {
            aptModel: isFetchTeslaAppointments ? AptModel.TESLA : undefined,
            status,
            timerangeBegin:
              action.payload.timerangeBegin ??
              this.appointmentService.getTimeRangeBeginFor(new Date(Date.now()), jobType),
            jobType,
            customerCaseId,
            itemNumbers: itemNumbers ?? [],
            formattedAddress,
            ...(position?.lat && position?.lng && { latitude: +position.lat, longitude: +position.lng })
          };
          if (isFetchTeslaAppointments) {
            payload.latitude = null;
            payload.longitude = null;
          }
          const isTeslaAppointmentWithServiceCenterId = !isFetchTeslaAppointments || action.payload.serviceCenterId;
          return this.isValidScId(serviceCenterIds?.[0] ?? null) && isTeslaAppointmentWithServiceCenterId
            ? { ...payload, serviceCenters: serviceCenterIds }
            : payload;
        }
      ),
      map((payload: AppointmentPayload) => AppointmentActions.getAppointments({ payload }))
    )
  );

  public getNextAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.getNextAppointments),
      map((action: { payload: { date: string; aptModel?: AptModel } }) => action.payload),
      withLatestFrom(
        this.appointmentFacade.position$,
        this.appointmentFacade.status$,
        this.damageFacade.requiredService$,
        this.customerCaseFacade.customerCaseId$
      ),
      this.waitForProductId(),
      map(
        ([payload, position, status, jobType, customerCaseId, itemNumbers]: [
          { date: string; aptModel?: AptModel },
          { lat: number; lng: number },
          JobStatus,
          JobType,
          string,
          string
        ]) =>
          AppointmentActions.fetchNextAppointments({
            payload: {
              latitude: position.lat,
              longitude: position.lng,
              status,
              timerangeBegin: this.appointmentService.getTimeRangeBeginFor(new Date(payload.date), jobType, false),
              jobType,
              customerCaseId,
              itemNumbers: itemNumbers ?? [],
              aptModel: payload.aptModel
            } as AppointmentPayload
          })
      )
    )
  );

  public fetchEarliestPossibleAppointmentDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.fetchEarliestPossibleAppointmentDate),
      mergeMap(() =>
        this.appointmentService.fetchEarliestPossibleAppointmentDate().pipe(
          map((res: EarliestPossibleAppointmentDateResponse) =>
            AppointmentActions.fetchEarliestPossibleAppointmentDateSuccess({
              payload: {
                desiredAppointmentDate: res.desiredMobileDate,
                desiredAppointmentId: res.desiredAppointmentdId
              }
            })
          ),
          catchError((error: Error) =>
            of(AppointmentActions.fetchEarliestPossibleAppointmentDateFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  // eslint-disable-next-line max-params
  public constructor(
    private readonly actions$: Actions,
    private readonly appointmentService: AppointmentService,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly resumeFacade: ResumeFacade,
    private readonly productFacade: ProductFacade
  ) {}

  private waitForProductId<T>() {
    return (source: Observable<T>) =>
      source.pipe(
        mergeMap((values: T) =>
          this.customerCaseFacade.customerCase$.pipe(
            filter((cc: CustomerCase) => !!cc && !!cc.shoppingCartEntries?.length),
            take(1),
            map((customerCase: CustomerCase) => {
              if (Array.isArray(values)) {
                const itemIdsArray: string[] = customerCase.shoppingCartEntries.map(
                  (item: ChosenProduct) => item.itemNumber
                );
                return [...values, itemIdsArray];
              } else {
                throw new Error("waitForProductId is only for observables with arrays");
              }
            })
          )
        )
      );
  }

  private isValidScId(scId: string) {
    return scId && scId !== "null" && scId !== PLACEHOLDER;
  }
}
