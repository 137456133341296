/* eslint-disable sonarjs/no-duplicate-string */
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { filter, tap } from "rxjs/operators";
import { CustomerCaseActions } from "@cg/olb/state";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { VAPsEventFlag, VAPsSuccessEventData, VAPsTriggerEventData } from "@cg/olb/shared";
import { AdditionalProduct } from "@cg/shared";

export enum VAPsStep {
  OFFER = "vaps-offer",
  ADD = "vaps-added",
  REMOVE = "vaps-removed",
  BOOK = "vaps-booking"
}

@Injectable()
export class VapsTrackingEffects {
  public constructor(
    private actions$: Actions,
    private trackingService: TrackingService
  ) {}

  public VAPOfferTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.offerVAP),
        filter(({ payload }: { payload: VAPsTriggerEventData }) => this.shouldTrack(payload.flags)),
        tap(({ payload }: { payload: VAPsTriggerEventData }) => {
          this.trackVAPsEvent(payload.product, VAPsStep.OFFER);
        })
      ),
    { dispatch: false }
  );

  public VAPAddedTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.addVAPSuccess),
        filter(({ payload }: { payload: VAPsSuccessEventData }) => this.shouldTrack(payload.flags)),
        tap(({ payload }: { payload: VAPsSuccessEventData }) => {
          this.trackVAPsEvent(payload.product, VAPsStep.ADD);
        })
      ),
    { dispatch: false }
  );

  public VAPRemovedTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.removeVAPSuccess),
        filter(({ payload }: { payload: VAPsSuccessEventData }) => this.shouldTrack(payload.flags)),
        tap(({ payload }: { payload: VAPsSuccessEventData }) => {
          this.trackVAPsEvent(payload.product, VAPsStep.REMOVE);
        })
      ),
    { dispatch: false }
  );

  public VAPBookingTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.bookVAPSuccess),
        filter(({ payload }: { payload: VAPsTriggerEventData }) => this.shouldTrack(payload.flags)),
        tap(({ payload }: { payload: VAPsTriggerEventData }) => {
          this.trackVAPsEvent(payload.product, VAPsStep.BOOK);
        })
      ),
    { dispatch: false }
  );

  public trackVAPsEvent(product: AdditionalProduct, eventAction: VAPsStep): void {
    const payload: Partial<TrackingEvent> = {
      event: "ga-event",
      eventCategory: "olb",
      eventAction: eventAction,
      eventLabel: this.mapAdditionalProductToEventLabel(product)
    };

    this.trackingService.trackEvent(payload);
  }

  private shouldTrack(flags: VAPsEventFlag[]): boolean {
    return flags === null || flags === undefined || !flags.includes(VAPsEventFlag.NO_TRACKING);
  }

  private mapAdditionalProductToEventLabel(product: AdditionalProduct): string {
    switch (product) {
      case AdditionalProduct.PROTECT:
        return "protect";
      case AdditionalProduct.WIPER:
        return "wipers";
      default:
        return product;
    }
  }
}
