import { createAction } from "@ngrx/store";

export const openExitOverlay = createAction("[Exit Overlay] Open");
export const closeExitOverlay = createAction("[Exit Overlay] Close");

export const confirm = createAction("[Exit Overlay] Confirm");
export const cancel = createAction("[Exit Overlay] Cancel");

export const exitOverlayActions = {
  openExitOverlay,
  closeExitOverlay,
  confirm,
  cancel
};
