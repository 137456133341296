<cg-drawer [showCloseIcon]="false" [headline]="headline" [content]="content" [footer]="footer"></cg-drawer>

<ng-template #headline>
  <cg-olb-headline [title]="'exitOverlay.titleABTestVariant1' | transloco"></cg-olb-headline>
</ng-template>

<ng-template #content>
  <div class="flex flex-col gap-4 t:gap-6">
    <cg-paragraph [content]="{ text: 'exitOverlay.subheadingABTestVariant1' | transloco }"></cg-paragraph>
    <hr class="border-cg-anthracite-light-80" />
    <div>
      <cg-olb-headline
        class="part-[headline]:!mb-4"
        [type]="'h3'"
        [title]="'exitOverlay.actionsABTestVariant1.title' | transloco"
      ></cg-olb-headline>
      <button
        cg-base-button
        class="mb-2 w-full part-[text]:text-cg-anthracite part-[icon-text-wrapper]:x-[flex-row,w-full,px-5,bg-cg-gray-light-70,flex-row-reverse] part-[icon]:x-[h-4,w-4] part-[icon-text-wrapper]:hover:bg-cg-anthracite-light-80 part-[icon-text-wrapper]:active:bg-cg-anthracite-light-80 t:block"
        [btnText]="'exitOverlay.actionsABTestVariant1.button' | transloco"
        (click)="openSurvey()"
      ></button>
      <cg-paragraph
        class="[&>p]:x-[!text-[13px],!leading-5]"
        [content]="{ text: 'exitOverlay.actionsABTestVariant1.paragraph' | transloco }"
      ></cg-paragraph>
    </div>
    <hr class="border-cg-anthracite-light-80" />
  </div>
</ng-template>

<ng-template #footer>
  <div class="flex flex-row justify-start gap-4">
    <button
      cg-base-button
      class="w-full part-[text]:text-cg-anthracite part-[icon-text-wrapper]:x-[flex-row,w-full,px-5,bg-cg-gray-light-70,flex-row-reverse] part-[icon]:x-[h-4,w-4] part-[icon-text-wrapper]:hover:bg-cg-anthracite-light-80 part-[icon-text-wrapper]:active:bg-cg-anthracite-light-80 t:block"
      [btnText]="'exitOverlay.buttonABTestVariant1.abort' | transloco"
      (click)="leaveComponent(true)"
    ></button>
    <button
      cg-base-button
      class="w-full"
      [btnText]="'exitOverlay.buttonABTestVariant1.return' | transloco"
      (click)="leaveComponent(false)"
    ></button>
  </div>
</ng-template>
