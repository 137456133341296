import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { ParagraphComponent } from "@cg/core/ui";
import { arrowsIcon, closeIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { BaseButtonComponent, CccPhoneInfoComponent, DrawerComponent, PhoneNumber } from "@cg/shared";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import { ExitOverlayFacade } from "../../+state/exit-overlay.facade";

@Component({
  selector: "cg-exit-overlay",
  templateUrl: "./exit-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    DrawerComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    CccPhoneInfoComponent,
    BaseButtonComponent
  ]
})
export class ExitOverlayComponent {
  public readonly PhoneNumber = PhoneNumber;

  public readonly forwardIcon: Icon = arrowsIcon;
  public readonly backwardIcon: Icon = closeIcon;

  public constructor(private exitOverlayFacade: ExitOverlayFacade) {}

  public leaveComponent(leave: boolean) {
    if (leave) {
      this.exitOverlayFacade.confirm();
    }
    this.exitOverlayFacade.close();
  }
}
